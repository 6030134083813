import { useState, useEffect } from "react";
import { Dimensions, Platform } from "react-native";

const useWindowWidth = () => {
  // Initialize state with the current width
  if (Platform.OS !== "web") {
    return Dimensions.get("window").width;
  }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Subscribe to window resize event
    window.addEventListener("resize", handleResize);

    // Clean up function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures effect is only run on mount and unmount

  return windowWidth;
};

export default useWindowWidth;
