import { BarChart, LineChart } from "react-native-chart-kit";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Alert,
  Platform,
} from "react-native";
import { useEffect, useState } from "react";
import { FlatList, Button, Pressable } from "react-native";
import supabase from "./supabase";
import { GraphComponent } from "./GraphComponent";
import Icon from "react-native-vector-icons/Feather";

import data_manager, { parseDateFromString } from "./data_manager";

import {
  calculateAverageValueFromDayValueMap,
  getEarliestDateFromStringArray,
  getCumulativeGraphData,
  pointsMetricFunctionMap,
  percentMetricFunctionMap,
} from "./utils";

import commonStyles from "./commonStyles";

import StatsBlock, { StatRow } from "./StatsBlock";
import {
  NAVIGATOR_COLOR,
  BACKGROUNDBLUE1,
  BACKGROUNDBLUE2,
  graphWidth,
} from "./constants";

import useWindowWidth from "./useWindowWidth";
import CustomizeHabitModal from "./CustomizeHabitModal";

// given a object where the key is the date, and the value is a count for that date,
// return a list of objects with keys x and y, where x is the date and y is the count
// and the list is sorted by the date

const StatsGraphDisplay = ({ projectName, projectType }) => {
  // props:
  // projectName: string
  // raw data has for each button press, the date and the value
  // dayValueMap is a map where key is the date and value is the count for that date

  // graphData is a list of objects with keys x and y,
  // where x is the date and y is the count
  const windowWidth = useWindowWidth();
  const [isMinimized, setIsMinimized] = useState(false);

  const [data, setData] = useState({
    dayValueMap: {},
    graphData: [],
    cumulativeGraphData: [],
    isLoaded: false,
  });

  const [metricList, setMetricList] = useState(
    data_manager.getMetricList(projectName)
  );

  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data_response = await data_manager.get_data(
          projectName,
          projectType
        );
        if (data_response.error) {
          console.error(
            "StatsGraphDisplay fetchData get_data error",
            data_response.error
          );
        }
        if (data_response.data) {
          setData({
            dayValueMap: data_response.data.dayValueMap,
            graphData: data_response.data.graphData,
            cumulativeGraphData: data_response.data.cumulativeGraphData,
            isLoaded: true,
          });
          setMetricList(data_manager.getMetricList(projectName));
        }
      } catch (error) {
        console.error("StatsGraphDisplay fetchData error", error);
      }
    };
    fetchData();
  }, [projectName]);

  const handleUpdate = async (value) => {
    const response = await data_manager.handleUpdate(
      projectName,
      projectType,
      value
    );

    if (response.error) {
      console.error("add point error", response.error);
      setUploadError(true);
    } else {
      const newData = response.data.graphData.map((item, index) => {
        return { x: index, y: Math.random() };
      });
      setData({
        dayValueMap: response.data.dayValueMap,
        graphData: response.data.graphData,
        cumulativeGraphData: response.data.cumulativeGraphData,
        isLoaded: true,
      });
    }
  };

  const useVerticalLayout = windowWidth < 650;
  let statsGraphWidth = Math.min(windowWidth * 0.9, 650);
  let statsGraphHeight = useVerticalLayout
    ? statsGraphWidth * 1.4
    : statsGraphWidth * 0.5;
  let statsWidth = useVerticalLayout ? statsGraphWidth : statsGraphWidth * 0.3;
  let statsHeight = useVerticalLayout ? 160 : statsGraphHeight;

  const graphWidth = useVerticalLayout
    ? statsGraphWidth
    : statsGraphWidth - statsWidth;
  const graphHeight = useVerticalLayout
    ? statsGraphHeight - statsHeight
    : statsGraphHeight;

  let minimizedMetric = null;
  if (isMinimized && metricList.length > 0) {
    const metricFunctionMap =
      projectType == "points"
        ? pointsMetricFunctionMap
        : percentMetricFunctionMap;
    const metric = metricList[0];
    minimizedMetric = isMinimized && (
      <View
        style={{
          flexDirection: windowWidth < 650 ? "column" : "row",
          flexShrink: 1,
        }}
      >
        <Text style={{ fontSize: 16, color: "black", marginLeft: 10 }}>
          {metric}
          {":"}
        </Text>
        <Text
          style={{
            fontSize: 16,
            color: "black",
            marginLeft: 10,
            fontWeight: "bold",
          }}
        >
          {metricFunctionMap[metric](data.dayValueMap)}
        </Text>
      </View>
    );
  }

  return (
    <View
      style={[
        {
          backgroundColor: "white",
          borderRadius: 10,
          marginVertical: 10,
        },
        commonStyles.shadow,
      ]}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "hsla(217, 20%, 92%, 1)",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: isMinimized ? 10 : 0,
          borderBottomRightRadius: isMinimized ? 10 : 0,
          paddingLeft: 0,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 10,
          width: statsGraphWidth,
          // height: 55,
          // height: 55,
        }}
      >
        <Text
          style={{
            margin: 0,
            fontSize: 20,
            color: "black",
            marginLeft: 10,
            flexShrink: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {projectName}{" "}
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: 10,
              // backgroundColor: "#DDDDDD",
              borderRadius: 5,
            }}
            onPress={() => setIsMinimized(!isMinimized)}
          >
            <Icon
              name={isMinimized ? "plus-square" : "minus-square"}
              size={16}
              color="#000"
            />
          </TouchableOpacity>
        </Text>

        {!isMinimized && (
          <CustomizeHabitModal
            projectName={projectName}
            projectType={projectType}
            setParentMetricList={setMetricList}
            metricListProp={metricList}
          />
        )}
        {minimizedMetric}
        {projectType == "points" ? (
          <Pressable
            onPress={() => handleUpdate(1)}
            className="buttonShadow"
            style={({ pressed }) => [
              {
                backgroundColor: pressed ? NAVIGATOR_COLOR : BACKGROUNDBLUE2,
                color: "white",
                padding: 8,
                borderRadius: 5,
              },
              commonStyles.shadow0,
            ]}
          >
            <Text style={{ color: "white" }}>Add Point</Text>
          </Pressable>
        ) : (
          <View style={{ flexDirection: "row" }}>
            <Pressable
              onPress={() => handleUpdate(1)}
              className="buttonShadow"
              style={({ pressed }) => [
                {
                  backgroundColor: pressed ? NAVIGATOR_COLOR : BACKGROUNDBLUE2,
                  color: "white",
                  padding: 10,
                  borderRadius: 5,
                  marginRight: 10,
                  // paddingRight: 5,
                },
                commonStyles.shadow0,
              ]}
            >
              <Text style={{ color: "white" }}>Add Success</Text>
            </Pressable>
            <Pressable
              onPress={() => handleUpdate(0)}
              className="buttonShadow"
              style={({ pressed }) => [
                {
                  backgroundColor: pressed ? NAVIGATOR_COLOR : BACKGROUNDBLUE2,
                  color: "white",
                  padding: 10,
                  borderRadius: 5,
                },
                commonStyles.shadow0,
              ]}
            >
              <Text style={{ color: "white" }}>Add Fail</Text>
            </Pressable>
          </View>
        )}
      </View>
      {!isMinimized && (
        <View
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: statsGraphWidth,
            height: statsGraphHeight,
          }}
        >
          <View
            style={[
              useVerticalLayout
                ? styles.verticalLayout
                : styles.horizontalLayout,
              {
                display: "flex",
              },
            ]}
          >
            <StatsBlock
              dayValueMap={data.dayValueMap}
              projectType={projectType}
              useVerticalLayout={useVerticalLayout}
              width={statsWidth}
              height={statsHeight}
              metricListProp={metricList}
            />
            {data.graphData.length > 0 && (
              <GraphComponent
                // graphData={data.graphData}
                graphData={data.graphData}
                cumulativeGraphData={data.cumulativeGraphData}
                aggMethod="sum"
                noYLabels={true}
                projectType={projectType}
                verticalLayout={useVerticalLayout}
                isLoaded={data.isLoaded}
                width={graphWidth}
                height={graphHeight}
                // verticalLayout={true}
              />
            )}
            {data.graphData.length == 0 && (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: 10,
                }}
              >
                <Text>{`No Data yet. Press ${
                  projectType == "points"
                    ? '"Add Point"'
                    : '"Success" or "Fail"'
                } to add data`}</Text>
              </View>
            )}
          </View>
        </View>
      )}

      {uploadError ? (
        // <Alert severity="error">
        <Text>There was an error uploading data. Please try again</Text>
      ) : // </Alert>
      null}
    </View>
  );
};

const styles = StyleSheet.create({
  horizontalLayout: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  verticalLayout: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
});

export default StatsGraphDisplay;
