import { View, Text, StyleSheet } from "react-native";
import useWindowWidth from "./useWindowWidth";
import commonStyles from "./commonStyles";

const BetaWarning = () => {
  // component that warns the user that this is in beta, and they should
  // expect bugs and missing features.

  const windowWidth = useWindowWidth();

  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // margin: 10,
        marginTop: 20,
      }}
    >
      <View
        style={[
          styles.container,
          commonStyles.shadow,
          { width: Math.min(windowWidth * 0.9, 650) },
        ]}
      >
        <Text style={styles.text}>
          Welcome to the Beta version of HabitStat! There may be bugs and
          missing features, but we're working hard to fix them. If you have any
          issues or requests, please email{" "}
          <Text style={{ textDecorationLine: "underline" }}>
            hello@habitstat.com
          </Text>
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    // height: 100,
  },
  text: {
    fontFamily: "Helvetica",
    fontSize: 24,
  },
});

export default BetaWarning;
