const SECTION_1_1_CONTENT =
  "The way we think about habits is wrong. We think of habits as yes/no. We're either doing it successfully or we're not. " +
  "But that's not helpful, because when when we make a mistake on one day, we feel like a failure and give up\n\n" +
  "Instead, think of habits as a probability. Each day, there's some chance you'll be successful, and some chance you won't. " +
  "Every time you do the habit right, the chance of success goes up. Every time you do it wrong, the chance of success goes down\n\n" +
  "The purpose of HabitStat is to keep track of each success and failure so you can track that probability over time, so you can watch your probability of success increase until you're " +
  "doing the habit automatically without any effort.";

const SECTION_1_2_CONTENT =
  "You have two mental systems, your conscious and your subconscious. When you're trying to make a habit, you're trying to change your subconscious (because you want the new behavior to be automatic, without requiring thinking about it, or willpower).\n\n" +
  "I've found the best way to teach my subconscious is to train it like how you train a dog. Don't try to teach it through logical explanations or willpower. Simply 1. reward it when it does something right, and 2. give it lots of opportunities to practice.\n\n" +
  'For HabitStat, we reward our subconscious by tracking every success. We see our score go higher, the line on the graph get better, and we feel good because of it. The other key is to give ourselves lots of opportunities to practice the habit. See the "Examples" section to see this in action';

const SECTION_1_3_CONTENT =
  "It's tempting to start by making lots of different habits for everything we want to improve about ourselves. Then there's too many to keep track of, and we get overwhelmed and give up.\n\n" +
  "It's better to start with a small number of habits. Choose 1-3 things you want to focus on at first, and really focus on doing those well.\n\n" +
  "Once you've gotten good at those habits, you can add more, but it's much better to start slow and gradually build up than to try to do too much and fail at all of them";

const SECTION_2_1_CONTENT =
  'At the bottom of the "Habits" tab, there\'s an "Add New Habit" button. Click this to create a new habit. First choose the name of your habit. Then choose the type of habit. HabitStat has two types of habits, "Points" habits and "Success/Fail" habits.\n\n' +
  'For Points habits, you get one point each time you do the habit. For example, you could make a "have good posture" habit, where you get a point each time you stand up straight.\n\n' +
  'For "Success/Fail" habits you log each time you do the habit correctly, but you also count the number of times you failed. E.g. an "Avoid desserts" habit where there is the possibility that you do the habit wrong (eat the dessert), and you want to track the failures too';

const SECTION_2_2_CONTENT =
  'Every time you do your habit, press "Add Point" for Points Habits, or "Add Success" or "Add Fail"\n\n' +
  "Then watch your progress over time! Every time you add a success or point, feel proud of yourself. Watch your scores get better, and build a graph showing how you've made progress and made a new habit.\n\n" +
  "This excitement at watching your statistics get better over time will help keep you motivated to keep working on the habit. ";

const SECTION_3_1_CONTENT =
  "The more opportunities we have to practice a habit, the faster we will learn. " +
  "For example, let's say you create a habit called \"Don't eat desserts\", and you " +
  "count it as a success if you don't at any desserts that day, and a failure if you do " +
  "it desserts that day. In this case, you get one new data point every day. " +
  "That's not a lot.\n\n" +
  'A better way to do it is to have a habit called "Resist Desserts", where you count it ' +
  "as a success every time you want to eat a dessert but resist, and it's a failure every time " +
  "you do eat a dessert. You might want to eat a dessert 20 times a day. It's important to " +
  "recognize every time you successfully resist the urge.";

const SECTION_3_2_CONTENT =
  "Being happy is a skill. You can practice it get better. Here's how\n\n" +
  "Make a Points Habit called \"Happiness\". For this habit, you take a moment to be happy. You can think about something you're grateful for. Or take a moment to smile. If you're stressed, take a deep breath and let go of the tension in your body. The key thing is to focus on feeling the emotion, don't just do it on autopilot without feeling the emotion.\n\n" +
  "Then start practicing. Try to do this at least 10 times a day to start. Eventually, build up to 50+ times a day. By feeling happy so many times a day, it will eventually become automatic, and you'll just randomly feel happy.\n\n" +
  "This is one of the most important habits you can work on. I started doing this and it's had a huge impact on my mental state and overall well being. Being happier is not always easy, but if you consistently practice, it will make an impact";

const SECTION_3_3_CONTENT =
  "To break bad habits, you can try to simply resist the urge to do the bad habit. This will be very hard since it's a constant battle of willpower. Here's a better way.\n\n" +
  "When you want to do the bad habit, pause for a second. Ask yourself, why do you want to do the bad habit? Remind yourself that you don't actually want to do this. Try to feel the desire you have to do the bad habit, and to let that desire go. Doing this will help you disconnect from the desire to do the bad habit.\n\n" +
  "For example, I have a bad habit of watching Youtube too much. When I want to watch a video, I ask myself, why? I realized that when I was stressed, I would deal with the stress by going to Youtube. That's a bad way to deal with stress because all it does is procrastinate, it doesn't solve what's stressing me. So now when I'm stressed, instead of watching a youtube video, I take a deep breath, meditate for a minute to reduce stress, and then try to face what is stressing me out.\n\n" +
  "This works because instead of practicing resisting the bad habit (in which case you'll still want to do the bad habit, you'll just get better at resisting), you are practicing not wanting to do the bad habit. As you practice more, your desire to do the bad habit will slowly reduce, until you are free.";

const SECTION_3_4_CONTENT =
  "Our mental state depends on what our mental habits are.\n\n" +
  'For example, if you need to do something, and your first thought is "this will go badly" or "I can\'t do this", then you will be stressed and demoralized. This is due to a habit of reacting negatively to anything new you see.\n\n' +
  'To change this habit, treat it as a bad habit that you need to replace with a good habit. Create a HabitStat Points habit called "Positive Reframe". Every time you notice yourself thinking "I can\'t do this", take a pause, and imagine yourself accomplishing it. Then add a point for your Positive Reframe habit.\n\n' +
  "When you first start, you'll only notice yourself reacting negatively sometimes, but as you practice more, you'll catch yourself more, and before long, you'll react more positively to everything, and be happier because of it. Aim for 5 points a day to start, and gradually build up until you're at 25 positive reframes every day. ";

export {
  SECTION_1_1_CONTENT,
  SECTION_1_2_CONTENT,
  SECTION_1_3_CONTENT,
  SECTION_2_1_CONTENT,
  SECTION_2_2_CONTENT,
  SECTION_3_1_CONTENT,
  SECTION_3_2_CONTENT,
  SECTION_3_3_CONTENT,
  SECTION_3_4_CONTENT,
};
