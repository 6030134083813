import { View, Text, StyleSheet } from "react-native";
import styles from "./styles";
import { Pressable } from "react-native";
import { useEffect, useState } from "react";
// import { Auth } from "@supabase/auth-ui-react";
import Auth from "./Auth";
import supabase from "./supabase";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useWindowDimensions } from "./utils";
import Background from "./Background";
import ContactUs from "./ContactUs";

// Define the "Settings" screen
function SettingsScreen({ navigation }) {
  // const [session, setSession] = useState(null);

  // useEffect(() => {
  //   supabase.auth.getSession().then(({ data: { session } }) => {
  //     setSession(session);
  //   });

  //   supabase.auth.onAuthStateChange((_event, session) => {
  //     setSession(session);
  //   });
  // }, []);

  return (
    <Background>
      <Auth navigation={navigation} />
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ContactUs emailAddress="hello@habitstat.com" emailSubject="Hello" />
      </View>
    </Background>
  );
}

export default SettingsScreen;
