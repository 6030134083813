import React from "react";
import { Text, TouchableOpacity, Linking, Alert, View } from "react-native";
import showAlert from "./alert";
import useWindowWidth from "./useWindowWidth";

const ContactUs = ({
  emailAddress,
  text = "Contact Us",
  emailSubject = "",
  emailBody = "",
  children,
}) => {
  const windowWidth = useWindowWidth();

  // const handlePress = () => {
  //   const mailto = `mailto:${emailAddress}?subject=${encodeURIComponent(
  //     emailSubject
  //   )}&body=${encodeURIComponent(emailBody)}`;
  //   Linking.canOpenURL(mailto)
  //     .then((supported) => {
  //       if (!supported) {
  //         showAlert(
  //           "Unable to open email client. Please email us at " + emailAddress
  //         );
  //       } else {
  //         showAlert(
  //           "Unable to open email client. Please email us at " + emailAddress
  //         );
  //         return Linking.openURL(mailto);
  //       }
  //     })
  //     .catch((err) => console.error("An error occurred", err));
  // };

  return (
    // <TouchableOpacity
    //   onPress={handlePress}
    //   style={{ width: Math.min(windowWidth * 0.9, 650) }}
    // >
    <View style={{ width: Math.min(windowWidth * 0.9, 650) }}>
      <Text style={{ fontSize: 20 }}>
        Questions? Found a bug? Have a feature request? Contact us at{" "}
        <Text style={{ textDecorationLine: "underline" }}>{emailAddress}</Text>
      </Text>
    </View>
    // </TouchableOpacity>
  );
};

export default ContactUs;
