import Background from "./Background";
import React, {
  View,
  StyleSheet,
  Text,
  SectionList,
  Platform,
  ScrollView,
  Button,
  Pressable,
  TouchableOpacity,
} from "react-native";
import { Fragment } from "react";
import { VIEW_ELEVATION } from "./constants";
import { useState } from "react";
import commonStyles from "./commonStyles";
import useWindowWidth from "./useWindowWidth";
import Icon from "react-native-vector-icons/FontAwesome";
import {
  SECTION_1_1_CONTENT,
  SECTION_1_2_CONTENT,
  SECTION_1_3_CONTENT,
  SECTION_2_1_CONTENT,
  SECTION_2_2_CONTENT,
  SECTION_3_1_CONTENT,
  SECTION_3_2_CONTENT,
  SECTION_3_3_CONTENT,
  SECTION_3_4_CONTENT,
} from "./content";

const TableOfContents = () => {
  const windowWidth = useWindowWidth();
  const componentWidth = Math.min(windowWidth * 0.9, 650);
  const [showTableOfContents, setShowTableOfContents] = useState(true);
  // const [currentId, setCurrentId] = useState(contents[0].subsections[0].id);
  const [currentId, setCurrentId] = useState("0");

  const contents = [
    {
      title: "Table of Contents",
      id: "0",
      subsections: [
        {
          title: "Oops",
          id: "0",
          content: "",
        },
      ],
    },
    {
      title: "HabitStat Philosophy",
      id: "1.",
      subsections: [
        {
          title: "Treat habits as a probability",
          id: "1.1.",
          content: SECTION_1_1_CONTENT,
        },
        {
          title: "Train your brain like a dog",
          id: "1.2.",
          content: SECTION_1_2_CONTENT,
        },
        {
          title: "Start small",
          id: "1.3.",
          content: SECTION_1_3_CONTENT,
        },
      ],
    },
    {
      title: "How to use HabitStat",
      id: "2.",
      subsections: [
        {
          title: "Choose the type of habit",
          id: "2.1.",
          content: SECTION_2_1_CONTENT,
        },
        {
          title: "Watch your progress",
          id: "2.2.",
          content: SECTION_2_2_CONTENT,
        },
      ],
    },
    {
      title: "Examples",
      id: "3.",
      subsections: [
        {
          title: "Resisting Dessert: More practice is better",
          id: "3.1.",
          content: SECTION_3_1_CONTENT,
        },
        {
          title: "How to be happier",
          id: "3.2.",
          content: SECTION_3_2_CONTENT,
        },
        {
          title: "Breaking bad habits",
          id: "3.3.",
          content: SECTION_3_3_CONTENT,
        },
        {
          title: "Overcoming anxiety/self-doubt/imposter syndrome/etc.",
          id: "3.4.",
          content: SECTION_3_4_CONTENT,
        },
      ],
    },
    {
      title: "Coming soon",
      id: "4.",
      subsections: [
        {
          title: "List of upcoming features",
          id: "4.1.",
          content:
            "HabitStat is currently in Beta version and we're rapidly adding new features." +
            "Some of the new features coming are:\n\n" +
            '1. "Numbers" Habit, for tracking numbers (e.g. the number of minutes you spent cleaning)\n' +
            "2. Customizable statistics and graphs, so you can choose different statistics to display for each habit (e.g. current streak)\n" +
            "3. Reorder and delete habits\n" +
            "4. Export your data to a spreadsheet\n" +
            "5. Iphone + Android apps\n" +
            "Is there another feature you'd like to see? Let us know at hello@habitstat.com",
        },
      ],
    },
  ];

  const getCurrentContent = () => {
    for (let section of contents) {
      for (let subsection of section.subsections) {
        if (subsection.id === currentId) {
          return [subsection.content, subsection.title];
        }
      }
    }
    return ["Content not found", "Title not found"];
  };

  const moveToNext = () => {
    let found = false;
    console.log("move to next");
    for (let section of contents) {
      console.log("section", section);
      for (let subsection of section.subsections) {
        if (found) {
          setCurrentId(subsection.id);
          console.log("update current id", subsection.id);
          return;
        }
        if (subsection.id === currentId) {
          found = true;
        }
      }
    }
  };

  const moveToPrevious = () => {
    let prevId = null;
    for (let section of contents) {
      for (let subsection of section.subsections) {
        if (subsection.id === currentId && prevId) {
          setCurrentId(prevId);
          return;
        }
        prevId = subsection.id;
      }
    }
  };

  const [currentContent, currentTitle] = getCurrentContent();

  const tableOfContentsComponet = (
    <View
      style={[
        commonStyles.shadow,
        {
          backgroundColor: "white",
          padding: 10,
          borderRadius: 10,
          margin: 10,
          width: componentWidth,
        },
      ]}
    >
      {contents.map((section) => {
        if (section.id === "0") {
          return null;
        }
        return (
          <View key={section.id}>
            <Text style={{ fontSize: 22, marginVertical: 4 }}>
              {section.id} {section.title}
            </Text>
            {section.subsections.map((subsection) => {
              return (
                // <Text
                //   key={subsection.id}
                //   style={{
                //     fontSize: 18,
                //     marginBottom: 4,
                //   }}
                // >
                //   {"    "} {subsection.id}{" "}
                //   <Text
                //     key={subsection.id}
                //     onPress={() => setCurrentId(subsection.id)}
                //     style={{
                //       fontSize: 18,
                //       marginBottom: 4,
                //       // textDecorationLine: "underline",
                //     }}
                //   >
                //     {subsection.title}
                //   </Text>
                // </Text>
                <View
                  key={subsection.id}
                  style={{
                    flexDirection: "row",
                    marginBottom: 4,
                    alignItems: "flex-start",
                  }}
                >
                  <View
                    style={{
                      width: 50,
                      // marginRight: 8,
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        textAlign: "left",
                        marginLeft: 20,
                        // marginRight: 2,
                      }}
                    >
                      {subsection.id}
                    </Text>
                  </View>
                  <View style={{ flex: 1, marginLeft: 3 }}>
                    <Text
                      onPress={() => setCurrentId(subsection.id)}
                      style={{
                        fontSize: 18,
                        // textDecorationLine: "underline",
                      }}
                    >
                      {subsection.title}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );

  const prevBlock = (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        // backgroundColor: "#DDDDDD",
        borderRadius: 5,
      }}
      onPress={moveToPrevious}
    >
      <Icon name="arrow-left" size={20} color="#000" />
      <Text style={{ marginLeft: 10, fontSize: 20 }}>Prev. Section</Text>
    </TouchableOpacity>
  );
  const nextBlock = (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        // backgroundColor: "#DDDDDD",
        borderRadius: 5,
      }}
      onPress={moveToNext}
    >
      <Text style={{ marginRight: 10, fontSize: 20 }}>Next Section</Text>
      <Icon name="arrow-right" size={20} color="#000" />
    </TouchableOpacity>
  );

  const tocBlock = (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 10,
        // backgroundColor: "#DDDDDD",
        borderRadius: 5,
      }}
      onPress={() => setCurrentId("0")}
    >
      <Text style={{ marginLeft: 10, fontSize: 20 }}>Table of Contents</Text>
    </TouchableOpacity>
  );
  const contentNavigation =
    windowWidth < 600 ? (
      <Fragment>
        {tocBlock}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: componentWidth,
            // marginBottom: 10,
          }}
        >
          {prevBlock}
          {nextBlock}
        </View>
      </Fragment>
    ) : (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: componentWidth,
          marginBottom: 10,
        }}
      >
        {prevBlock}
        {tocBlock}
        {nextBlock}
      </View>
    );
  const contentComponent =
    currentContent && currentTitle ? (
      <Fragment>
        <Text
          style={{
            textAlign: "center",
            fontSize: 24,
            textDecorationLine: "underline",
            marginBottom: 10,
          }}
        >
          {currentTitle}
        </Text>
        <View
          style={[
            commonStyles.shadow,
            {
              backgroundColor: "white",
              width: componentWidth,
              borderRadius: 10,
              padding: 10,
              marginBottom: 10,
            },
          ]}
        >
          <Text style={{ fontSize: 18 }}>{currentContent}</Text>
        </View>
      </Fragment>
    ) : null;

  return (
    <ScrollView>
      <View
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {contentNavigation}
        {currentId === "0" ? tableOfContentsComponet : contentComponent}
        {/* {tableOfContentsComponet} */}
      </View>
    </ScrollView>
  );
};

function InstructionsScreen() {
  return (
    <Background>
      <TableOfContents />
    </Background>
  );
}

function InstructionsScreenV1() {
  const learnMoreWidth = 600;
  const instructions = [
    {
      text: "Instructions. Blah blah blah",
    },
  ];

  return (
    <Background>
      <View style={styles.topcontainer}>
        <View style={styles.container}>
          <SectionList
            contentContainerStyle={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            style={{
              marginBottom: 100,
              width: "100%",
            }}
            sections={[{ title: "Instructions", data: instructions }]}
            renderItem={({ item }) => {
              return (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: learnMoreWidth,
                  }}
                >
                  <View
                    elevation={VIEW_ELEVATION}
                    style={[
                      styles.shadow,
                      {
                        backgroundColor: "white",
                        borderRadius: 10,
                        marginVertical: 8,
                        width: "98%",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.listitem,
                        {
                          marginHorizontal: 10,
                          marginVertical: 10,
                        },
                      ]}
                    >
                      {item.text}
                    </Text>
                  </View>
                </View>
              );
            }}
            renderSectionHeader={({ section: { title } }) => (
              <Text style={styles.h1}>{title}</Text>
            )}
            stickySectionHeadersEnabled={false}
          />
        </View>
      </View>
    </Background>
  );
}

const styles = StyleSheet.create({
  scrollcontainer: {
    width: "100%",
  },
  shadow: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  },
  topcontainer: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
  },
  container: {
    // maxWidth: learnMoreWidth,
    width: "100%",
    overflow: "hidden",
    height: "auto",
  },
  listitem: {
    fontSize: Platform.OS === "web" ? 14 : 18,
  },
  listContainer: {
    backgroundColor: "red",
    height: "auto",
  },
  text: {
    color: "white",
  },
  h1: {
    fontSize: 30,
    textAlign: "center",
  },
});

export default InstructionsScreen;
