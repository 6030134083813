import "react-native-url-polyfill/auto";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createClient } from "@supabase/supabase-js";

const SUPABASE_URL = "https://gffhidclaamnebchkugv.supabase.co";
const SUPABSE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdmZmhpZGNsYWFtbmViY2hrdWd2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODUzODA2MzEsImV4cCI6MjAwMDk1NjYzMX0.0K-Cy9mTEtYBlQ2KkZCUFNSBAy-HZPt4JQugYNub87I";

// AsyncStorage.clear();

const supabase = createClient(SUPABASE_URL, SUPABSE_KEY, {
  auth: {
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
});

export default supabase;
