import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  Modal,
  Pressable,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Background from "./Background";
import { BACKGROUNDBLUE1, BACKGROUNDBLUE2, NAVIGATOR_COLOR } from "./constants";
import data_manager from "./data_manager";
import commonStyles from "./commonStyles";
import { PointsExample, PercentExample } from "./examples";
import showAlert from "./alert";
import Icon from "react-native-vector-icons/FontAwesome";
import calculateAverageValueFromDayValueMap from "./utils";

const CustomMetric = ({ metricName, isCheckedProp, updateState }) => {
  const [isChecked, setChecked] = useState(isCheckedProp);

  useEffect(() => {
    setChecked(isCheckedProp);
  }, [isCheckedProp]);

  return (
    <View style={styles.metricContainer}>
      <Text style={styles.metricText}>{metricName}</Text>
      <TouchableOpacity
        onPress={() => {
          updateState(metricName, isChecked);
        }}
      >
        <Icon
          name={isChecked ? "check-square-o" : "square-o"}
          size={30}
          color={isChecked ? "black" : "grey"}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  metricContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 10,
  },
  metricText: {
    fontSize: 20,
  },
});

export default CustomMetric;
