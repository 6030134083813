function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const getTodayDateString = () => {
  const today = new Date();
  return today.toLocaleDateString("en-US");
};

function getEarliestDateFromStringArray(dateStrings) {
  if (!Array.isArray(dateStrings) || dateStrings.length === 0) {
    return null; // Return null for an empty or invalid input
  }

  // Helper function to convert day/month/year format to Date object

  // Parse the first date string and initialize it as the earliest date
  let earliestDate = parseDateFromString(dateStrings[0]);

  // Iterate through the rest of the date strings
  for (let i = 1; i < dateStrings.length; i++) {
    const currentDate = parseDateFromString(dateStrings[i]);

    if (currentDate && currentDate < earliestDate) {
      earliestDate = currentDate;
    }
  }

  return earliestDate;
}

function parseDateFromString(dateString) {
  const parts = dateString.split("/");
  if (parts.length === 3) {
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10) - 1; // Months are 0-based (0 for January)
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }
  return null;
}

function calculateCurrentStreak(dayValueMap, projectType) {
  let currentDate = new Date();
  let consecutiveDays = 0;

  // Helper function to format a date as MM/DD/YYYY
  function formatDate(date) {
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();

    return [month, day, year].join("/");
  }

  while (true) {
    const formattedDate = formatDate(currentDate);

    // Check if the date exists in the map and has value at least 1
    if (projectType === "points") {
      if (dayValueMap[formattedDate] && dayValueMap[formattedDate] >= 1) {
        consecutiveDays++;
      } else {
        break;
      }
    } else {
      if (
        dayValueMap[formattedDate] &&
        dayValueMap[formattedDate]["success"] >= 1 &&
        dayValueMap[formattedDate]["success"] ==
          dayValueMap[formattedDate]["total"]
      ) {
        consecutiveDays++;
      } else {
        break;
      }
    }

    // Move to the previous day
    currentDate.setDate(currentDate.getDate() - 1);
  }

  return consecutiveDays;
}

function filterDayValueMapByDateRange(dayValueMap, numberOfDays) {
  const today = new Date(); // Get the current date

  if (numberOfDays === -1) {
    const dayValueKeys = Object.keys(dayValueMap);
    const earliestDate = getEarliestDateFromStringArray(dayValueKeys);
    const diffTime = Math.abs(today - earliestDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    numberOfDays = diffDays;
    return [Object.entries(dayValueMap), numberOfDays];
  }

  const cutoffDate = new Date(
    today.getTime() - numberOfDays * 24 * 60 * 60 * 1000
  ); // Calculate the cutoff date

  const filteredData = Object.entries(dayValueMap).filter(([date]) => {
    // const entryDate = new Date(date);
    const entryDate = parseDateFromString(date);
    return entryDate > cutoffDate && entryDate <= today; // Filter data within the date range
  });

  const earliestDate = getEarliestDateFromStringArray(
    filteredData.map((x) => x[0])
  );

  const diffTime = Math.abs(today - earliestDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  numberOfDays = diffDays;

  return [filteredData, numberOfDays];
}

function calculateTotalFromDayValueMap(
  dayValueMap,
  numberOfDays,
  projectType,
  aggTarget = "success"
) {
  const [filteredData, updatedNumberOfDays] = filterDayValueMapByDateRange(
    dayValueMap,
    numberOfDays
  );
  numberOfDays = updatedNumberOfDays;

  if (filteredData.length === 0) {
    return 0;
  }
  if (projectType === "points") {
    const sum = filteredData.reduce(
      (accumulator, [, points]) => accumulator + points,
      0
    );
    return sum;
  } else {
    const successes = filteredData.reduce(
      (accumulator, keyValPair) =>
        accumulator + (keyValPair[1]["success"] || 0),
      0
    );
    if (aggTarget === "success") {
      return successes;
    } else {
      const total = filteredData.reduce(
        (accumulator, keyValPair) =>
          accumulator + (keyValPair[1]["total"] || 0),
        0
      );
      return total - successes;
    }
  }
}

function calculateAverageValueFromDayValueMap(
  dayValueMap,
  numberOfDays,
  projectType,
  multiplyBy100 = false
) {
  // day value map is an object with keys as days and values as counts for that day
  // numberOfDays is the number of days to calculate the average over
  // if numberOfDays is -1, calculate the average over all days
  const [filteredData, updatedNumberOfDays] = filterDayValueMapByDateRange(
    dayValueMap,
    numberOfDays
  );
  numberOfDays = updatedNumberOfDays;

  if (filteredData.length === 0) {
    return 0;
  }

  let average = 0;
  let diff = 0;
  // if (projectType === "points" && numberOfDays === -1) {
  //   debugger;
  // }
  if (projectType === "points") {
    const sum = filteredData.reduce(
      (accumulator, [, points]) => accumulator + points,
      0
    );

    average = sum / numberOfDays;
    diff = Math.abs(
      (sum + 1) / filteredData.length - sum / filteredData.length
    );
  } else {
    const successes = filteredData.reduce(
      (accumulator, keyValPair) =>
        accumulator + (keyValPair[1]["success"] || 0),
      0
    );
    const total = filteredData.reduce(
      (accumulator, keyValPair) => accumulator + (keyValPair[1]["total"] || 0),
      0
    );
    average = successes / total;
    diff = Math.min(
      Math.abs((successes + 1) / (total + 1) - successes / total),
      Math.abs(successes / (total + 1) - successes / total)
    );
  }

  if (multiplyBy100) {
    average *= 100;
    diff *= 100;
  }
  let precision = 2;
  // uses enough sig figs so that when you change by one,
  // it changes the average
  if (diff != 0) {
    precision = Math.max(2, Math.ceil(Math.log10(1 / diff)));
  }

  return average.toFixed(precision);
}

function getCumulativeGraphData(dayValueMap, projectType) {
  const data = [];
  if (projectType == "points") {
    let cumulative = 0;
    Object.entries(dayValueMap).forEach(([date, points]) => {
      cumulative += points;
      data.push({ x: date, y: cumulative });
    });
  } else {
    let cumulativeSuccess = 0;
    let cumulativeTotal = 0;
    Object.entries(dayValueMap).forEach(([date, counts]) => {
      cumulativeSuccess += counts["success"];
      cumulativeTotal += counts["total"];
      data.push({ x: date, y: cumulativeSuccess / cumulativeTotal });
    });
  }
  return data;
}

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const pointsMetricFunctionMap = {
  "Total points today": (dayValueMap) => {
    return calculateAverageValueFromDayValueMap(dayValueMap, 1, "points");
  },
  "Avg. daily points, last 7 days": (dayValueMap) => {
    return calculateAverageValueFromDayValueMap(dayValueMap, 7, "points");
  },
  "Avg. daily points, all time": (dayValueMap) => {
    return calculateAverageValueFromDayValueMap(dayValueMap, -1, "points");
  },
  "Current Streak": (dayValueMap) => {
    return calculateCurrentStreak(dayValueMap, "points");
  },
  "Total Points": (dayValueMap) => {
    return calculateTotalFromDayValueMap(dayValueMap, -1, "points");
  },
};

const metricEncoderMap = {
  "Total points today": "a1",
  "Avg. daily points, last 7 days": "a7",
  "Avg. daily points, all time": "a0",
  "Success Rate Today": "b1",
  "Success Rate Last 30 Days": "b30",
  "Success Rate All Time": "b0",
  "Total Successes Today": "c",
  "Total Fails Today": "d",
  "Current Streak": "e",
  "Total Points": "f",
  "Total Successes": "g",
  "Total Fails": "h",
};

const metricDecoderMap = {};
for (const [key, value] of Object.entries(metricEncoderMap)) {
  metricDecoderMap[value] = key;
}

const percentMetricFunctionMap = {
  "Success Rate Today": (dayValueMap) => {
    return `${calculateAverageValueFromDayValueMap(
      dayValueMap,
      1,
      "percent",
      true
    )}%`;
  },
  "Total Successes Today": (dayValueMap) => {
    const today = getTodayDateString();
    if (dayValueMap[today]) {
      return dayValueMap[today]["success"] || 0;
    }
    return 0;
  },
  "Total Fails Today": (dayValueMap) => {
    const today = getTodayDateString();
    if (dayValueMap[today]) {
      return dayValueMap[today]["total"] - dayValueMap[today]["success"];
    }
    return 0;
  },
  "Success Rate Last 30 Days": (dayValueMap) => {
    return `${calculateAverageValueFromDayValueMap(
      dayValueMap,
      30,
      "percent",
      true
    )}%`;
  },
  "Success Rate All Time": (dayValueMap) => {
    return `${calculateAverageValueFromDayValueMap(
      dayValueMap,
      -1,
      "percent",
      true
    )}%`;
  },
  "Current Streak": (dayValueMap) => {
    return calculateCurrentStreak(dayValueMap, "percent");
  },
  "Total Successes": (dayValueMap) => {
    return calculateTotalFromDayValueMap(dayValueMap, -1, "percent", "success");
  },
  "Total Fails": (dayValueMap) => {
    return calculateTotalFromDayValueMap(dayValueMap, -1, "percent", "fails");
  },
};

export {
  useWindowDimensions,
  getTodayDateString,
  parseDateFromString,
  calculateAverageValueFromDayValueMap,
  getEarliestDateFromStringArray,
  getCumulativeGraphData,
  sleep,
  pointsMetricFunctionMap,
  percentMetricFunctionMap,
  metricEncoderMap,
  metricDecoderMap,
  calculateCurrentStreak,
};
