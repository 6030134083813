import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  Modal,
  Pressable,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Background from "./Background";
import { BACKGROUNDBLUE1, BACKGROUNDBLUE2, NAVIGATOR_COLOR } from "./constants";
import data_manager from "./data_manager";
import commonStyles from "./commonStyles";
import { PointsExample, PercentExample } from "./examples";
import showAlert from "./alert";

const ShowPressButton = ({ onPress, title, isPressed }) => {
  return (
    <Pressable
      onPress={onPress}
      style={[
        commonStyles.button,
        isPressed ? {} : styles.elevated,
        { backgroundColor: isPressed ? BACKGROUNDBLUE2 : BACKGROUNDBLUE1 }, // Change color when pressed
      ]}
    >
      {({ pressed }) => (
        <Text
          style={[commonStyles.buttonText, isPressed ? styles.underline : {}]}
        >
          {title}
        </Text>
      )}
    </Pressable>
  );
};

const CreateHabitComponent = ({ addNewHabit, navigation }) => {
  const [projectName, setProjectName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [modalVisiable, setModalVisible] = useState(false);
  const [error, setError] = useState("");

  const handleProjectNameChange = (name) => {
    if (name.length > 50) {
      setError("Project name must be less than 50 characters");
    } else {
      if (error !== "") setError("");
      setProjectName(name);
    }
  };

  const handleNewProject = () => {
    setModalVisible(true);
  };

  const handleSubmit = async () => {
    // Replace with your form submission logic
    if (projectName === "") {
      setError("Please enter habit name");
      return;
    } else if (projectType === "") {
      setError('Please select habit type, "Points" or "Success/Fail"');
      return;
    }

    const { data, error } = await data_manager.addHabit(
      projectName,
      projectType
    );

    if (error) {
      showAlert(`Error: ${error}`);
    } else {
      addNewHabit({ project_name: projectName, project_type: projectType });
      setModalVisible(false);
      setProjectName("");
      setProjectType("");
    }
  };

  return (
    <View style={[styles.container]}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisiable}
        onRequestClose={() => {
          setModalVisible(false);
        }}
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
        }}
      >
        <TouchableOpacity
          style={{ width: "100%", height: "100%", zIndex: 0 }}
          onPress={() => setModalVisible(false)}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent
            }}
          >
            <TouchableWithoutFeedback>
              <View
                style={{
                  backgroundColor: "white",
                  height: 500,
                  width: 350,
                  borderRadius: 10,
                  padding: 20,
                }}
              >
                <Pressable
                  style={({ pressed }) => [
                    commonStyles.closeButton,
                    pressed ? null : commonStyles.shadow,
                    { zIndex: 1 },
                  ]}
                  onPress={() => setModalVisible(false)}
                >
                  <Text style={commonStyles.closeButtonText}>X</Text>
                </Pressable>
                <Text style={[styles.label, { textAlign: "center" }]}>
                  Create New Habit
                </Text>
                <TextInput
                  style={styles.input}
                  onChangeText={handleProjectNameChange}
                  value={projectName}
                  placeholder="Enter Habit Name"
                />
                <Text
                  style={[styles.label, { textAlign: "center", fontSize: 18 }]}
                >
                  Habit Type
                </Text>
                <View style={styles.buttonContainer}>
                  <ShowPressButton
                    onPress={() => setProjectType("points")}
                    title="Points"
                    isPressed={projectType === "points"}
                  />
                  <ShowPressButton
                    onPress={() => setProjectType("percent")}
                    title="Success/Fail"
                    isPressed={projectType === "percent"}
                  />
                </View>
                {/* {projectName === "points" ? <PointsExample /> : null}
                {projectName === "percent" ? <PercentExample /> : null} */}
                {/* {projectName == null ? (<View> <Text> Press "Points" or "Success/Fail" to </Text></View>) : null} */}
                <View>
                  <Text style={{ textAlign: "center" }}>
                    Select "Points" or "Success/Fail" {"\n\n"}
                  </Text>
                  <Text>
                    "Points" habits give you 1 point each time you do the habit
                    (e.g. counting how many times each day you take a deep
                    breath and relax) {"\n\n"}
                  </Text>
                  <Text>
                    "Success/Fail" habits are either a success or a failure each
                    day (e.g. when you wanted to eat extra dessert, did you
                    resist it or not?) {"\n\n"}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setModalVisible(false);
                      navigation.navigate("Instructions");
                    }}
                  >
                    <Text>
                      See{" "}
                      <Text style={{ textDecorationLine: "underline" }}>
                        Instructions
                      </Text>{" "}
                      for more information
                    </Text>
                  </TouchableOpacity>
                </View>
                <Pressable
                  onPress={handleSubmit}
                  style={[
                    commonStyles.shadow,
                    {
                      backgroundColor: "hsla(217, 59%, 45%, 1)",
                      margin: 2,
                      marginTop: 20,
                      borderRadius: 10,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 26,
                      margin: 4,
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Create Habit
                  </Text>
                </Pressable>
                {error ? <Text style={styles.errorText}>{error}</Text> : null}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableOpacity>
      </Modal>
      <View style={styles.modalView}>
        <Pressable
          onPress={handleNewProject}
          style={({ pressed }) => {
            return [
              commonStyles.button,
              commonStyles.shadow,
              { backgroundColor: pressed ? NAVIGATOR_COLOR : "white" }, // Change color when pressed
            ];
          }}
        >
          <Text style={commonStyles.buttonText}>ADD NEW HABIT</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  elevated: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "lightgray",
    borderRadius: 15,
    padding: 5,
    zIndex: 1,
  },

  container: {
    flex: 1,
    padding: 20,
    alignItems: "center",
  },
  formContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    height: 40,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    padding: 10,
    marginVertical: 10,
  },
  label: {
    textAlign: "left",
    color: "#000",
    marginBottom: 5,
    fontSize: 22,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10,
  },
  addButtonContainer: {
    marginVertical: 20,
  },
  errorText: {
    color: "red",
    marginTop: 10,
  },
  underline: {
    textDecorationLine: "underline",
  },
});

export default CreateHabitComponent;
