import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";

import {
  calculateAverageValueFromDayValueMap,
  calculateAverageSuccessRateFromDayValueMap,
  getEarliestDateFromStringArray,
  getTodayDateString,
  pointsMetricFunctionMap,
  percentMetricFunctionMap,
} from "./utils";

const StatRow = ({ label, value, minimize = false }) => {
  if (value == undefined) {
    value = "missing";
  }
  if (isNaN(value)) {
    // sometimes it thinks 100% is a NaN and it throws an error
    value = value.toString();
  }
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: 5,
        // borderColor: "black",
        // borderWidth: 1,
        paddingRight: 5,
      }}
    >
      <Text
        style={{
          margin: 0,
          padding: 0,
          color: "hsla(190, 14%, 20%, 0.92)",
          // backgroundColor: "green",
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          margin: 0,
          // paddingTop: 0,
          padding: 0,
          paddingLeft: 20,
          fontWeight: "bold",
          fontSize: 30,
          lineHeight: 30,
          // lineHeight: 0.8,
          color: "black",
          // includeFontPadding: false,
          // textAlignVertical: "top",
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const StatsBlock = ({
  dayValueMap,
  projectType,
  useVerticalLayout,
  width,
  height,
  metricListProp,
}) => {
  const topStyle = [
    styles.statsBlockContainer,
    useVerticalLayout ? null : { display: "flex" },
    {
      height: height,
      width: width,
      // backgroundColor: "blue"
    },
  ];

  const [metricList, setMetricList] = useState(metricListProp || []);
  // console.log("statsblock metric list", metricList, metricListProp);

  useEffect(() => {
    if (metricListProp) {
      setMetricList(metricListProp);
    }
  }, [metricListProp]);

  if (projectType == "points") {
    return (
      <View style={topStyle}>
        {metricList.map((metric) => {
          console.log(
            "metric",
            "points",
            metric,
            pointsMetricFunctionMap[metric]
          );
          console.log(pointsMetricFunctionMap);

          return (
            <StatRow
              key={metric}
              label={`${metric}:`}
              value={pointsMetricFunctionMap[metric](dayValueMap)}
            />
          );
        })}
      </View>
    );
  } else {
    const today = getTodayDateString();
    let successToday = 0;
    let failToday = 0;
    if (dayValueMap[today]) {
      successToday = dayValueMap[today]["success"] || 0;
      failToday = dayValueMap[today]["total"] - successToday;
    }

    return (
      <View style={topStyle}>
        {metricList.map((metric) => {
          // console.log(
          //   "metric",
          //   "percent",
          //   metric,
          //   percentMetricFunctionMap[metric]
          // );
          // console.log(percentMetricFunctionMap);
          return (
            <StatRow
              key={metric}
              label={`${metric}:`}
              value={percentMetricFunctionMap[metric](dayValueMap)}
            />
          );
        })}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  statsBlockContainer: {
    flexDirection: "column",
    paddingLeft: 10,
    flexWrap: "wrap",
    // backgroundColor: "red",
  },
});

export default StatsBlock;
export { StatRow };
