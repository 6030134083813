import React, { useState, useEffect } from "react";

import { View, Text, Dimensions, processColor, Platform } from "react-native";
import { VIEW_ELEVATION } from "./constants";

import Victory from "./victory"; // this will default to victory.js or victory.native.js
import { BACKGROUNDBLUE2 } from "./constants";
import useWindowWidth from "./useWindowWidth";
// depending on the compilation target platform.

// const VictoryBar = Victory.VictoryBar;
const VictoryChart = Victory.VictoryChart;
const VictoryLine = Victory.VictoryLine;
const VictoryAxis = Victory.VictoryAxis;
const VictoryTheme = Victory.VictoryTheme;
const VictoryLegend = Victory.VictoryLegend;
const VictoryContainer = Victory.VictoryContainer;
const VictoryScatter = Victory.VictoryScatter;

const mdyToDate = (mdy) => {
  return new Date(parseInt(mdy[2]), parseInt(mdy[0]) - 1, parseInt(mdy[1]));
};

const selectLabelsSimple = (labels) => {
  const targetSize = 6;
  const modulus = Math.ceil(labels.length / targetSize);
  const lastDayModulus = (labels.length - 1) % modulus;

  return labels
    .map((x) => {
      const [month, day, year] = x.split("-");
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    })
    .map((x, i) => {
      if (i == 0 && lastDayModulus / modulus >= 0.8) return x;
      return i % modulus == lastDayModulus ? x : "";
    });
};

const selectLabels = (labels) => {
  const labelsParsed = labels.map((x) => {
    const [month, day, year] = x.split("-");
    return [month, day, year];
  });
  let returnLabels = [];
  if (true) {
    returnLabels = selectLabelsSimple(labels);
    // returnLabels = labelsParsed.map((mdy: string[]) => {
    //   if (mdy[1] == "1") {
    //     return mdyToDate(mdy).toLocaleDateString("en-US", {
    //       month: "short",
    //       day: "numeric",
    //     });
    //   } else if (["7", "14", "21"].includes(mdy[1])) {
    //     return mdyToDate(mdy).toLocaleDateString("en-US", {
    //       month: "short",
    //       day: "numeric",
    //     });
    //   } else {
    //     return "";
    //   }
    // });
    // } else if (labels.length < 120) {
    // returnLabels = labelsParsed.map((mdy: string[]) => {
    // if (["1", "15"].includes(mdy[1])) {
    // return mdyToDate(mdy).toLocaleDateString("en-US", {
    // month: "short",
    // day: "numeric",
    // });
    // } else {
    // return "";
    // }
    // });
    // } else {
    // returnLabels = labelsParsed.map((mdy: string[]) => {
    // if (mdy[1] == "1") {
    // return mdyToDate(mdy).toLocaleDateString("en-US", {
    // month: "short",
    // day: "numeric",
    // });
    // } else {
    // return "";
    // }
    // });
    // todo: if there are a lot of months, logic to only show every other or something like that
  }
  return returnLabels;
};

const sum = (arr) => {
  return arr.reduce((a, b) => a + b, 0);
};

const condenseData = (labels, data, aggMethod) => {
  const targetSize = 30;
  if (labels.length <= targetSize) {
    return [labels, data];
  }
  let index = 0;
  let blockIndex = 1;
  const outputLabels = [];
  const outputData = [];
  while (index < labels.length - 1) {
    const currentGroup = [];
    let currentLabel = "";
    while (
      index < blockIndex * (labels.length / targetSize) &&
      index < labels.length - 1
    ) {
      if (labels[index] !== "") {
        currentLabel = labels[index];
      }
      currentGroup.push(data[index]);
      index++;
    }
    outputLabels.push(currentLabel);
    if (aggMethod === "min") {
      outputData.push(Math.min(...currentGroup));
    } else if (aggMethod === "max") {
      outputData.push(Math.max(...currentGroup));
    } else {
      outputData.push(sum(currentGroup) / currentGroup.length);
    }
    blockIndex += 1;
  }
  outputLabels.push(labels[labels.length - 1]);
  outputData.push(data[data.length - 1]);
  // outputData[0] = 0;
  return [outputLabels, outputData];
};

const GraphComponent = ({
  graphData,
  cumulativeGraphData,
  projectType,
  verticalLayout,
  width,
  height,
}) => {
  // props:
  // graphData is a list of {x: string, y: number} objects
  // cumulativeGraphData is a list of {x: string, y: number} objects,
  // but for cumulative data

  // useEffect(() => {
  //   const maxVal = Math.max(...ydata);
  //   let newYData;
  //   if (noYLabels) {
  //     setUnits("");
  //     newYData = ydata;
  //   } else {
  //     if (maxVal < 60) {
  //       setUnits(" sec");
  //       newYData = ydata;
  //     } else if (maxVal < 3600) {
  //       setUnits(" min");
  //       newYData = ydata.map((x) => x / 60);
  //     } else if (maxVal < 3600 * 24) {
  //       setUnits(" h");
  //       newYData = ydata.map((x) => x / 3600);
  //     } else {
  //       setUnits(" d");
  //       newYData = ydata.map((x) => x / 3600 / 24);
  //     }
  //   }

  //   setLabels(labels);

  //   let newLabels = selectLabels(labels);
  //   [newLabels, newYData] = condenseData(newLabels, newYData, aggMethod);
  //   // these hacks are for the x axis to not be too far to the right
  //   if (newLabels.length >= 24) {
  //     newLabels = newLabels.concat(["", "", ""]);
  //   } else if (newLabels.length >= 17) {
  //     newLabels = newLabels.concat(["", ""]);
  //   } else if (newLabels.length >= 9) {
  //     newLabels = newLabels.concat([""]);
  //   }
  //   setData({
  //     labels: newLabels, //.concat(["", ""]),
  //     datasets: [
  //       {
  //         data: newYData,
  //       },
  //     ],
  //   });
  // }, [ydata, labels]);

  // const width = Math.min(screenWidth * graphWidth, 400);

  const ydata = graphData.map((point) => point.y);

  if (Math.max(...ydata) == 0) {
    return (
      <View
        // elevation={VIEW_ELEVATION}
        style={{
          backgroundColor: "white",

          // borderRadius: 10,
          // marginBottom: 15,
          // shadowRadius: 1,
          // shadowOffset: { width: 0, height: 1 },
          // shadowOpacity: 0.8,
        }}
      >
        <Text style={{ textAlign: "center", margin: 5 }}>
          {" "}
          No data in this time range
        </Text>
      </View>
    );
  }

  const xAxis = {
    granularityEnabled: true,
    granularity: 1,
  };

  let yMin = 0;
  if (projectType === "percent") {
    yMin = Math.min(...ydata);
  }
  let yMax = Math.max(...ydata);
  yMax = Math.max(yMax, 1);
  if (projectType === "points") {
    yMax++;
  }

  const GraphDisplay = graphData.length == 1 ? VictoryScatter : VictoryLine;

  return (
    <View
      style={[
        {
          borderRadius: 10,
          // width: width,
          // height: height,
          // display: "flex",
          height: height,
          width: width,

          // zIndex: 1,
          // flex: 1,

          // width: verticalLayout ? width : width + 20,
        },
        // verticalLayout ? null : { flexGrow: 1 },
      ]}
    >
      <VictoryChart
        width={width}
        height={height}
        // containerComponent={
        //   Platform.OS === "web" ? (
        //     <VictoryContainer
        //       height={330}
        //       width={200}
        //       responsive={true}
        //       style={{
        //         pointerEvents: "auto",
        //         userSelect: "auto",
        //         touchAction: "auto",
        //         backgroundColor: "white",
        //       }}
        //     />
        //   ) : null
        // }
        containerComponent={
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              userSelect: "auto",
              touchAction: "auto",
            }}
          />
        }
        style={{
          pointerEvents: "none",
        }}
      >
        <GraphDisplay
          data={cumulativeGraphData}
          style={{
            data: {
              stroke: BACKGROUNDBLUE2,
              strokeWidth: 1.4,
            },
            // parent: { border: "1px solid #ccc" },
          }}
        />
        <GraphDisplay
          data={graphData}
          style={{
            data: { stroke: "black", strokeWidth: 1.4 },
            // parent: { border: "1px solid #ccc" },
          }}
        />

        <VictoryAxis
          // label="X-axis"
          style={{
            // axisLabel: { padding: 30 },
            grid: { stroke: "grey", strokeWidth: 0.5 },
          }}
          tickCount={3}
        />
        <VictoryAxis
          dependentAxis
          // label="Y-axis"
          style={{
            // axisLabel: { padding: 30 },
            grid: { stroke: "grey", strokeWidth: 0.5 },
          }}
          domain={[yMin, yMax]}
        />
        {graphData.length > 1 ? (
          <VictoryLegend
            y={10}
            x={50}
            orientation="horizontal"
            gutter={20}
            style={{
              border: { stroke: "black" },
              title: { fontSize: 20 },
            }}
            data={[
              { name: "Daily Data", symbol: { fill: "black" } },
              {
                name: "Average Data",
                symbol: { fill: BACKGROUNDBLUE2 },
              },
            ]}
          />
        ) : null}
      </VictoryChart>
    </View>
  );
};

export { GraphComponent };
