// import { View, LinearGradient } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { BACKGROUNDBLUE1, BACKGROUNDBLUE2 } from "./constants";

const Background = ({ children, style }) => {
  return (
    // <View>
    <LinearGradient
      // colors={[]}
      colors={[BACKGROUNDBLUE1, BACKGROUNDBLUE2]}
      style={[style, { flex: 1 }]}
      start={{ x: 0, y: 1 }} // 0,0 is top left
      end={{ x: 1, y: 0 }} // 1, 1 is bottom right
    >
      {children}
    </LinearGradient>
    // </View>
  );
};

export default Background;
