import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  Modal,
  Pressable,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Background from "./Background";
import { BACKGROUNDBLUE1, BACKGROUNDBLUE2, NAVIGATOR_COLOR } from "./constants";
import data_manager from "./data_manager";
import commonStyles from "./commonStyles";
import { PointsExample, PercentExample } from "./examples";

const ReorderHabitsModal = ({ habitsList, setHabitsList }) => {
  const [modalVisiable, setModalVisible] = useState(false);
  const [ogHabitsList, setOgHabitsList] = useState(habitsList);
  const [newOrder, setNewOrder] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setOgHabitsList(habitsList);
  }, [habitsList]);

  const addToNewOrder = (habit) => {
    const newNewOrder = newOrder
      .filter((x) => x.project_name != habit.project_name)
      .map((x, ind) => {
        return { ...x, habit_ind: ind };
      });
    habit["habit_ind"] = newNewOrder.length;
    setNewOrder([
      ...newOrder.filter((x) => x.project_name != habit.project_name),
      habit,
    ]);
  };

  const removeFromNewOrder = (habit) => {
    setNewOrder([
      ...newOrder
        .filter((x) => x.project_name != habit.project_name)
        .map((x, ind) => {
          return { ...x, habit_ind: ind };
        }),
    ]);
  };

  const commitReorder = () => {
    if (newOrder.length != ogHabitsList.length) {
      const missing = ogHabitsList
        .filter((habit) => !newOrder.includes(habit))
        .map((x) => x.project_name)
        .join(", ");

      setError(
        `You must include all habits in the new order. Missing: ${missing}`
      );
      return;
    }

    data_manager.setHabitList(newOrder);

    setError("");
    setHabitsList(newOrder);
    setModalVisible(false);
    setNewOrder([]);
  };

  return (
    <View style={[styles.container]}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisiable}
        onRequestClose={() => {
          setModalVisible(false);
        }}
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
        }}
      >
        <TouchableOpacity
          style={{ width: "100%", height: "100%", zIndex: 0 }}
          onPress={() => setModalVisible(false)}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent
            }}
          >
            <TouchableWithoutFeedback>
              <View
                style={{
                  width: 350,
                  padding: 20,
                }}
              >
                <Background style={{ borderRadius: 10, padding: 10 }}>
                  <Pressable
                    style={({ pressed }) => [
                      commonStyles.closeButton,
                      pressed ? null : commonStyles.shadow,
                      { zIndex: 1 },
                    ]}
                    onPress={() => setModalVisible(false)}
                  >
                    <Text style={commonStyles.closeButtonText}>X</Text>
                  </Pressable>
                  <View style={{ margin: 2, marginTop: 8, marginBottom: 12 }}>
                    <Text style={{ fontSize: 22, marginBottom: 4 }}>
                      Habits:
                    </Text>
                    <Text style={{ fontSize: 16 }}>
                      Click the habits in the new order that you want
                    </Text>
                  </View>

                  {habitsList.map((habit, index) =>
                    !newOrder
                      .map((x) => x.project_name)
                      .includes(habit.project_name) ? (
                      <Pressable
                        key={index}
                        onPress={() => addToNewOrder(habit)}
                        style={[commonStyles.shadow, styles.habitButton]}
                      >
                        <Text style={styles.habitButtonText} key={index}>
                          {habit.project_name}
                        </Text>
                      </Pressable>
                    ) : null
                  )}

                  <View style={{ margin: 2, marginTop: 8 }}>
                    <Text style={{ fontSize: 18 }}>New order:</Text>
                  </View>

                  <React.Fragment>
                    {newOrder.map((habit, index) => (
                      <Pressable
                        key={index}
                        style={[commonStyles.shadow, styles.habitButton]}
                        onPress={() => removeFromNewOrder(habit)}
                      >
                        <Text style={styles.habitButtonText} key={index}>
                          {habit.project_name}
                        </Text>
                      </Pressable>
                    ))}
                    <Text style={{ fontSize: 16, marginTop: 8 }}>
                      If a habit is in the wrong spot in the new order, you can
                      click the habit in the new order to temporarily remove it
                    </Text>
                  </React.Fragment>

                  <Pressable
                    onPress={() => {
                      commitReorder();
                    }}
                    style={[
                      commonStyles.shadow,
                      {
                        backgroundColor: "hsla(217, 59%, 45%, 1)",
                        margin: 2,
                        marginTop: 20,
                        borderRadius: 10,
                      },
                    ]}
                  >
                    <Text
                      style={{
                        fontSize: 26,
                        margin: 4,
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Finished
                    </Text>
                  </Pressable>
                  {error ? (
                    <Text style={{ fontSize: 18, margin: 4, color: "red" }}>
                      {error}
                    </Text>
                  ) : null}
                </Background>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableOpacity>
      </Modal>
      <View style={styles.modalView}>
        <Pressable
          onPress={() => setModalVisible(true)}
          style={({ pressed }) => {
            return [
              commonStyles.button,
              commonStyles.shadow,
              { backgroundColor: pressed ? NAVIGATOR_COLOR : "white" }, // Change color when pressed
            ];
          }}
        >
          <Text style={commonStyles.buttonText}>Reorder Habits</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  elevated: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "lightgray",
    borderRadius: 15,
    padding: 5,
    zIndex: 1,
  },

  container: {
    flex: 1,
    padding: 20,
    alignItems: "center",
  },
  formContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    height: 40,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    padding: 10,
    marginVertical: 10,
  },
  label: {
    textAlign: "left",
    color: "#000",
    marginBottom: 5,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10,
  },
  addButtonContainer: {
    marginVertical: 20,
  },
  errorText: {
    color: "red",
    marginTop: 10,
  },
  underline: {
    textDecorationLine: "underline",
  },
  habitButton: {
    backgroundColor: "white",
    margin: 2,
    borderRadius: 10,
    marginVertical: 4,
    paddingLeft: 8,
  },
  habitButtonText: {
    fontSize: 18,
    margin: 4,
    textAlign: "center",
  },
});

export default ReorderHabitsModal;
