import { Platform, StyleSheet } from "react-native";

const commonStyles = StyleSheet.create({
  shadow0: {
    shadowRadius: 1,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    elevation: 3,
  },
  shadow: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    elevation: 5,
  },
  button: {
    backgroundColor: "white",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: 150,
  },
  buttonText: {
    color: "blue",
    fontSize: 15,
    textAlign: "center",
  },
  closeButtonText: {
    fontSize: 16,
    color: "black",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "lightgray",
    borderRadius: 15,
    padding: 5,
    zIndex: 1,
  },
});

export default commonStyles;
