import { Platform, Alert as NativeAlert } from "react-native";

function showAlert(message) {
  // Check if the code is running in a web environment
  if (Platform.OS === "web") {
    alert(message);
  } else {
    // For React Native (iOS, Android, etc.)
    NativeAlert.alert(message);
  }
}

export default showAlert;
