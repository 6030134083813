import { GraphComponent } from "./GraphComponent";
import { dayValueMapToGraphXYData } from "./data_manager";
import { View, Text } from "react-native";

const PointsExample = () => {
  const dayValueMap = {
    "1/1/2024": 1,
    "1/2/2024": 2,
    "1/3/2024": 3,
    "1/4/2024": 4,
  };
  const projectName = "points";
  const { graphDataTemp, cumulativeGraphDataTemp } = dayValueMapToGraphXYData(
    dayValueMap,
    projectName
  );

  return (
    <View>
      <Text> Example Graph for "Points" Habit:</Text>
      <GraphComponent
        graphData={graphDataTemp}
        cumulativeGraphData={cumulativeGraphDataTemp}
        projectType={projectName}
        width={330}
        height={280}
      />
    </View>
  );
};

const PercentExample = () => {
  const dayValueMap = {
    "1/1/2024": { success: 1, total: 2 },
    "1/2/2024": { success: 2, total: 3 },
    "1/3/2024": { success: 3, total: 4 },
    "1/4/2024": { success: 4, total: 5 },
  };
  const projectName = "percent";
  const { graphData, cumulativeGraphData } = dayValueMapToGraphXYData(
    dayValueMap,
    projectName
  );

  return (
    <View>
      <GraphComponent
        graphData={graphData}
        cumulativeGraphData={cumulativeGraphData}
        projectType={projectName}
        width={200}
        height={200}
      />
    </View>
  );
};

export { PointsExample, PercentExample };
