import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  Modal,
  Pressable,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Background from "./Background";
import { BACKGROUNDBLUE1, BACKGROUNDBLUE2, NAVIGATOR_COLOR } from "./constants";
import data_manager from "./data_manager";
import commonStyles from "./commonStyles";
import { PointsExample, PercentExample } from "./examples";
import showAlert from "./alert";
import Icon from "react-native-vector-icons/FontAwesome";
import CustomMetric from "./CustomMetric";
import { pointsMetricFunctionMap, percentMetricFunctionMap } from "./utils";

const CustomizeHabitModal = ({
  projectName,
  projectType,
  setParentMetricList,
  metricListProp,
}) => {
  const [modalVisable, setModalVisible] = useState(
    // projectName == "pick"
    false
  );

  const metricOptions = Object.keys(
    projectType == "points" ? pointsMetricFunctionMap : percentMetricFunctionMap
  );

  const [metricList, setMetricList] = useState(metricListProp);
  const [graphMetricList, setGraphMetricList] = useState([]);

  useEffect(() => {
    setMetricList(metricListProp);
  }, [metricListProp]);

  const updateMetricList = (newMetric, shouldAdd = true) => {
    let newList = [...metricList, newMetric];
    if (shouldAdd) {
      newList = metricList.filter((metric) => metric !== newMetric);
    }
    setMetricList(newList);
    data_manager.setMetricList(projectName, newList);
    setParentMetricList(newList);
  };

  // const updateGraphMetricList = (newMetric, shouldAdd = true) => {
  //   if (shouldAdd) {
  //     setGraphMetricList([newMetric]);
  //   } else {
  //     setGraphMetricList(setGraphMetricList([]));
  //   }
  // };

  const [error, setError] = useState("");

  const handleProjectNameChange = (name) => {
    if (name.length > 50) {
      setError("Project name must be less than 50 characters");
    } else {
      if (error !== "") setError("");
      setProjectName(name);
    }
  };

  const handleNewProject = () => {
    setModalVisible(true);
  };

  const handleSubmit = async () => {
    // Replace with your form submission logic
    if (metricList.length <= 5) {
      data_manager.setMetricList(projectName, metricList, true);
      setModalVisible(false);
    }
  };

  const mainModalView = (
    <View
      style={{
        backgroundColor: "white",
        width: 350,
        // height: 500,
        borderRadius: 10,
        padding: 20,
      }}
    >
      <Pressable
        style={({ pressed }) => [
          commonStyles.closeButton,
          pressed ? null : commonStyles.shadow,
          { zIndex: 1 },
        ]}
        onPress={() => setModalVisible(false)}
      >
        <Text style={commonStyles.closeButtonText}>X</Text>
      </Pressable>
      {error ? <Text style={styles.errorText}>{error}</Text> : null}
      {/* <View>
        <Text> Graph Settings</Text>
        {graphOptions.map((metric) => (
          <CustomMetric
            metricName={metric}
            isCheckedProp={graphMetricList.includes(metric)}
            updateState={updateGraphMetricList}
          />
        ))}
      </View> */}
      <View>
        <Text> Metric Settings</Text>
        {metricOptions.map((metric) => (
          <CustomMetric
            metricName={metric}
            key={metric}
            isCheckedProp={metricList.includes(metric)}
            updateState={updateMetricList}
          />
        ))}
      </View>

      <View style={{ flexDirection: "column", alignItems: "center" }}>
        {metricList.length >= 6 ? (
          <View style={{ marginBottom: 8 }}>
            <Text style={{ fontSize: 18, color: "red" }}>
              Please choose at most 5 metrics
            </Text>
          </View>
        ) : null}
        <Pressable
          onPress={() => {
            handleSubmit();
          }}
          style={({ pressed }) => {
            return [
              commonStyles.button,
              commonStyles.shadow,
              {
                backgroundColor: pressed
                  ? NAVIGATOR_COLOR
                  : metricList.length <= 5
                  ? "white"
                  : "grey",
              }, // Change color when pressed
            ];
          }}
        >
          <Text style={[commonStyles.buttonText]}>Save changes</Text>
        </Pressable>
      </View>
    </View>
  );

  return (
    <View style={[styles.container]}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisable}
        onRequestClose={() => {
          setModalVisible(false);
        }}
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
        }}
      >
        <TouchableOpacity
          style={{ width: "100%", height: "100%", zIndex: 0 }}
          onPress={() => setModalVisible(false)}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent
            }}
          >
            <TouchableWithoutFeedback>{mainModalView}</TouchableWithoutFeedback>
          </View>
        </TouchableOpacity>
      </Modal>
      <View>
        <TouchableOpacity
          style={{
            flexDirection: "row",
            alignItems: "center",
            padding: 10,
            // backgroundColor: "#DDDDDD",
            borderRadius: 5,
          }}
          onPress={() => setModalVisible(true)}
        >
          <Icon name="gear" size={16} color="#000" />
          <Text style={{ marginLeft: 6, fontSize: 16 }}>Customize</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  elevated: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  closeButtonText: {
    fontSize: 16,
    color: "black",
  },
  container: {
    // flex: 1,
    // padding: 20,
    alignItems: "center",
  },
  formContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    padding: 10,
    marginVertical: 10,
  },
  label: {
    textAlign: "left",
    color: "#000",
    marginBottom: 5,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10,
  },
  addButtonContainer: {
    marginVertical: 20,
  },
  errorText: {
    color: "red",
    marginTop: 10,
  },
  underline: {
    textDecorationLine: "underline",
  },
});

export default CustomizeHabitModal;
