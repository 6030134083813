import supabase from "./supabase";
import {
  getTodayDateString,
  parseDateFromString,
  getEarliestDateFromStringArray,
  sleep,
  metricEncoderMap,
  metricDecoderMap,
} from "./utils";
import showAlert from "./alert";

const defaultPointsMetrics = [
  "Total points today",
  "Avg. daily points, last 7 days",
  "Avg. daily points, all time",
  "Current Streak",
];

const defaultPercentMetrics = [
  "Success Rate Today",
  "Total Successes Today",
  "Total Fails Today",
  // "Success Rate Last 30 Days",
  "Success Rate All Time",
  "Current Streak",
];

const rawDataToDateValueMap = (rawData, projectType) => {
  const data = {};
  if (projectType === "points") {
    rawData.forEach((x) => {
      if (data[x.date]) {
        data[x.date] += x.value;
      } else {
        data[x.date] = x.value;
      }
    });
  } else {
    rawData.forEach((x) => {
      if (!data[x.date]) {
        data[x.date] = { success: 0, total: 0 };
      }
      data[x.date]["total"] += 1;
      data[x.date]["success"] += x.value;
    });
  }
  return data;
};

const aggregateDataToDateValueMap = (aggregateData, projectType) => {
  const data = {};
  if (projectType === "points") {
    aggregateData.forEach((x) => {
      data[x.date] = x.total_pos;
    });
  } else {
    aggregateData.forEach((x) => {
      data[x.date] = {
        total: x.total_pos + x.total_neg,
        success: x.total_pos,
      };
    });
  }
  return data;
};

const dayValueMapToGraphXYData = (dayValueMap, projectType) => {
  //given an object dayValueMap where the key is the date and the value is the count for that date,
  //return a list of objects with keys x and y, where x is the date and y is the count
  //and the list is sorted by the date. if there is no data for a date, the count is 0
  const data = [];
  const cumulativeData = [];
  const entries = Object.keys(dayValueMap);
  //get the earliest date
  const earliestDate = getEarliestDateFromStringArray(entries);
  const today = new Date();
  // for each day between earliestDate and today, add an entry to data

  let cumulativeNum = 0;
  let cumulativeDenom = 0;

  for (
    let date = earliestDate;
    date <= today;
    date.setDate(date.getDate() + 1)
  ) {
    const dateString = date.toLocaleDateString("en-US");
    // check if there is data for that date
    const existingData = dayValueMap[dateString];

    data.push(
      dayValueMapToGraphXYDataHelper(existingData, dateString, projectType)
    );
    if (projectType === "percent") {
      if (data.length > 0 && data[0].y === -1) {
        data[0].y = 1;
      }
      for (let i = 1; i < data.length; i++) {
        if (data[i].y === -1) {
          data[i].y = data[i - 1].y;
        }
      }
    }

    // cumulativeData Section
    if (cumulativeData.length === 0) {
      if (projectType === "points") {
        cumulativeNum = data[0].y;
        cumulativeDenom = 1;
      } else {
        cumulativeNum = existingData ? existingData["success"] || 0 : 0;
        cumulativeDenom = existingData ? existingData["total"] || 0 : 0;
      }
    } else {
      if (projectType === "points") {
        cumulativeNum += data[data.length - 1].y;
        cumulativeDenom = data.length;
      } else {
        cumulativeNum += existingData ? existingData["success"] || 0 : 0;
        cumulativeDenom += existingData ? existingData["total"] || 0 : 0;
      }
    }
    cumulativeData.push({
      x: dateString,
      y: cumulativeNum / cumulativeDenom,
    });
  }

  return {
    graphDataTemp: data,
    cumulativeGraphDataTemp: cumulativeData,
  };
};

const dayValueMapToGraphXYDataHelper = (
  existingData,
  dateString,
  projectType
) => {
  if (projectType === "points") {
    if (existingData) {
      return { x: dateString, y: existingData };
    } else {
      return { x: dateString, y: 0 };
    }
  } else {
    if (existingData) {
      return {
        x: dateString,
        y: existingData["success"] / existingData["total"],
      };
    } else {
      return { x: dateString, y: -1 };
    }
  }
};

class DataManager {
  constructor({ downloadV2, uploadV2 }) {
    this.data = {};
    this.project_name_type_map = {};
    this.project_name_last_updated_map = {};
    this.failedUploads = [];
    this.failedUploadsV2 = [];
    this.currentlyRetrying = false;
    this.uploadV2 = uploadV2;
    this.downloadV2 = downloadV2;
    this.project_metric_list_map = {};
  }

  clear() {
    this.data = {};
    this.project_name_type_map = {};
    this.failedUploads = [];
    this.currentlyRetrying = false;
  }

  async setMetricList(projectName, metricList, upload = false) {
    this.project_metric_list_map[projectName] = metricList;
    if (upload) {
      await this._uploadCustomMetrics(projectName, metricList);
    }
  }

  getMetricList(projectName) {
    if (this.project_metric_list_map[projectName]) {
      return this.project_metric_list_map[projectName];
    }

    if (this.project_name_type_map[projectName] === "points") {
      return defaultPointsMetrics;
    }
    if (this.project_name_type_map[projectName] === "percent") {
      return defaultPercentMetrics;
    }
    return [];
  }

  async _download_custom_metrics(projectName) {
    const response = await supabase.auth.getUser();
    if (response.error) {
      showAlert("warning, saving custom metrics failed");
      console.error("get user error", response.error);
      return {
        error: response.error,
        data: null,
      };
    }
    const { data, error } = await supabase
      .from("custom_metrics")
      .select("*")
      .eq("id", response.data.user.id)
      .eq("project_name", projectName);
    if (error) {
      showAlert("warning, saving custom metrics failed");
      console.error("data manager get custom metrics error", error);
      return {
        error: error,
        data: null,
      };
    }
    if (data.length === 0) {
      return {
        error: null,
        data: null,
      };
    }
    const metricList = data[0].metrics
      .split(",")
      .map((x) => {
        return metricDecoderMap[x];
      })
      .filter((x) => {
        return x !== null;
      });
    this.project_metric_list_map[projectName] = metricList;
    return {
      error: null,
      data: metricList,
    };
  }

  async _uploadCustomMetrics(projectName, metricList) {
    console.log("upload", projectName, metricList, metricEncoderMap);
    const encodedMetricList = metricList
      .map((x) => {
        console.log("encode", x, metricEncoderMap, metricEncoderMap[x]);
        return metricEncoderMap[x];
      })
      .join(",");
    const response = await supabase.auth.getUser();
    if (response.error) {
      showAlert("warning, saving custom metrics failed");
      console.error("get user error", response.error);
      return {
        error: response.error,
        data: null,
      };
    }
    console.log("response.data", response.data);
    const { data, error } = await supabase.from("custom_metrics").upsert({
      id: response.data.user.id,
      project_name: projectName,
      metrics: encodedMetricList,
    });
    if (error) {
      showAlert("warning, saving custom metrics failed");
      console.error("data manager set custom metrics error", error);
      return {
        error: error,
        data: null,
      };
    }
    return {
      error: null,
      data: null,
    };
  }

  async _download_data(projectName, projectType) {
    console.log("download data V2:", this.downloadV2);
    try {
      const { data, error } = await supabase.auth.getUser();
      if (error) {
        console.error("data manager _download_data getUser error", error);
        return {
          data: null,
          error: error,
        };
      }

      const tableName = this.downloadV2 ? "aggregate_data" : "raw_data";

      const callSupabase = () =>
        supabase
          .from(tableName)
          .select("*")
          .eq("id", data.user.id)
          .eq("project_name", projectName);

      const [response, response2] = await Promise.all([
        callSupabase(),
        this._download_custom_metrics(projectName),
      ]);

      if (response.error) {
        console.error("data manager download data error", response.error);
        return {
          data: null,
          error: response.error,
        };
      }

      const dayValueMapTemp = this.downloadV2
        ? aggregateDataToDateValueMap(response.data, projectType)
        : rawDataToDateValueMap(response.data, projectType);

      if (response.data.length === 0) {
        return {
          data: {
            rawData: [],
            dayValueMap: {},
            graphData: [],
            cumulativeGraphData: [],
          },
          error: null,
        };
      }

      const { graphDataTemp, cumulativeGraphDataTemp } =
        dayValueMapToGraphXYData(dayValueMapTemp, projectType);

      return {
        data: {
          rawData: response.data,
          dayValueMap: dayValueMapTemp,
          graphData: graphDataTemp,
          cumulativeGraphData: cumulativeGraphDataTemp,
        },
        error: null,
      };
    } catch (error) {
      console.error("data manager _download data error", error);
      return {
        data: null,
        error: error,
      };
    }
  }

  async get_data(projectName, projectType) {
    try {
      if (this.data[projectName]) {
        return {
          data: this.data[projectName],
          error: null,
        };
      } else {
        const download_data_response = await this._download_data(
          projectName,
          projectType
        );

        if (download_data_response.error) {
          console.error(
            "data manager get data download data error",
            download_data_response.error
          );
          return {
            data: null,
            error: download_data_response.error,
          };
        }
        this.data[projectName] = download_data_response.data;
        this.project_name_type_map[projectName] = projectType;
        return {
          data: download_data_response.data,
          error: null,
        };
      }
    } catch (error) {
      return {
        data: null,
        error: error,
      };
    }
  }

  async getHabitsList() {
    const user = await supabase.auth.getUser();
    try {
      if (user.data.user == null) {
        return {
          data: [],
          error: "No user id",
        };
      }
      const { data, error } = await supabase
        .from("user_projects_v2")
        .select("*")
        .eq("id", user.data.user.id);
      if (error) {
        return {
          data: [],
          error: error,
        };
      }

      return {
        data: data.map((item) => {
          return {
            project_name: item.project_name,
            project_type: item.project_type,
            habit_ind: item.habit_ind,
          };
        }),
        error: null,
      };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return {
        data: [],
        error: error,
      };
    }
  }

  async setHabitList(habitsList) {
    // habitsList is a list of {project_name, project_type, habit_ind}
    const user = await supabase.auth.getUser();
    try {
      const getHabitsResponse = await this.getHabitsList();
      if (getHabitsResponse.data) {
        if (getHabitsResponse.data.length != habitsList.length) {
          return {
            data: [],
            error:
              "Habits list lengths do not match. Refresh the page and trying again",
          };
        } else {
          let { data, error } = await supabase.from("user_projects_v2").upsert(
            habitsList.map((item) => {
              return { ...item, id: user.data.user.id };
            })
          );
          if (error) {
            console.error("error setting habits list", error);
            return {
              data: [],
              error: error,
            };
          }
        }
      } else {
        return {
          data: [],
          error: error,
        };
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      return {
        data: [],
        error: error,
      };
    }
  }

  async addHabit(projectName, projectType) {
    try {
      let { data, error } = await supabase.auth.getUser();
      if (error) {
        console.error("data manager addHabit getUser error", error);
        return {
          data: null,
          error: error,
        };
      }
      this.project_name_type_map[projectName] = projectType;
      this.data[projectName] = {
        dayValueMap: {},
        graphData: [],
        cumulativeGraphData: [],
        isLoaded: false,
      };
      const existingHabitsResponse = await this.getHabitsList();
      if (existingHabitsResponse.error) {
        console.error("data manager addHabit getHabitsList error", error);
        return {
          data: null,
          error: error,
        };
      }
      if (
        existingHabitsResponse.data
          .map((x) => x.project_name)
          .includes(projectName)
      ) {
        return {
          data: null,
          error: "A habit with this name already exists",
        };
      }
      let response = await supabase.from("user_projects_v2").insert([
        {
          id: data.user.id,
          project_name: projectName,
          project_type: projectType,
          habit_ind: existingHabitsResponse.data.length,
        },
      ]);

      if (response.error) {
        console.error("data manager supabase upload error", response.error);
        return {
          error: response.error,
          data: null,
        };
      }
      return {
        error: null,
        data: null,
      };
    } catch (error) {
      console.error("data manager add habit error", error);
      return {
        error: error,
        data: null,
      };
    }
  }

  set_project_data(projectName, key, val) {
    if (!this.data[projectName]) {
      this.data[projectName] = {};
    }
    this.data[projectName][key] = val;
  }

  async retryFailedUploadsV1() {
    // TODO: the id_time is unique, so if people do two events
    // within the same second, then it'll fail
    // in the long term, supabase will store aggregate data
    // so that will be fixed then
    try {
      if (this.currentlyRetrying) {
        return {
          error: "already retrying",
          data: null,
        };
      }
      this.currentlyRetrying = true;
      while (this.failedUploads.length > 0) {
        const toInsert = this.failedUploads[0];
        await sleep(5000);
        const { data, error } = await this.supabaseUpload(toInsert);
        if (!error) {
          this.failedUploads = this.failedUploads.slice(1);
        } else {
          this.currentlyRetrying = false;
          return {
            error: error,
            data: null,
          };
        }
      }
      this.currentlyRetrying = false;
      return {
        error: null,
        data: null,
      };
    } catch (error) {
      showAlert("warning, V1 upload failed");
      console.error("data manager retry failed uploads error", error);
    }
  }

  async retryFailedUploadsV2() {
    // TODO: the id_time is unique, so if people do two events
    // within the same second, then it'll fail
    // in the long term, supabase will store aggregate data
    // so that will be fixed then
    try {
      if (this.currentlyRetrying) {
        return {
          error: "already retrying",
          data: null,
        };
      }
      this.currentlyRetrying = true;
      while (this.failedUploadsV2.length > 0) {
        const toInsert = this.failedUploadsV2[0];
        await sleep(5000);
        const { data, error } = await this.supabaseUpload(toInsert);
        if (!error) {
          this.failedUploadsV2 = this.failedUploadsV2.slice(1);
        } else {
          this.currentlyRetrying = false;
          return {
            error: error,
            data: null,
          };
        }
      }
      this.currentlyRetrying = false;
      return {
        error: null,
        data: null,
      };
    } catch (error) {
      showAlert("warning, V2 upload failed");
      console.error("data manager retry failed uploads v2 error", error);
    }
  }

  async supabaseUploadV1(toInsert) {
    const response = await supabase.auth.getUser();
    const userData = response.data;
    if (response.error) {
      showAlert("warning, V1 upload failed");
      console.error("get user error", userError);
      return {
        error: userError,
        data: null,
      };
    }
    toInsert["id"] = userData.user.id;
    const { data, error } = await supabase.from("raw_data").insert([toInsert]);

    if (error) {
      showAlert("warning, V1 upload failed");
      console.error("data manager add point error", error);
      this.failedUploads.push(toInsert);
    }
    this.retryFailedUploadsV1();
    return {
      error: error,
      data: data,
    };
  }

  async supabaseUploadV2(today, now, projectName, value) {
    console.log("supabase upload v2");
    try {
      const response = await supabase.auth.getUser();
      const userData = response.data;
      if (response.error) {
        showAlert("warning, V2 upload failed");
        console.error("get user error", userError);
        return {
          error: userError,
          data: null,
        };
      }

      let { data, error } = await supabase
        .from("aggregate_data")
        .select("*")
        .eq("id", userData.user.id)
        .eq("project_name", projectName)
        .eq("date", today);
      if (error) {
        console.error("supabaseUploadV2 get data error", error);
        this.failedUploadsV2.push(toInsert);
      }
      let toInsert;
      if (data.length === 0) {
        toInsert = {
          id: userData.user.id,
          date: today,
          last_updated: now,
          project_name: projectName,
          total_pos: 0,
          total_neg: 0,
        };
      } else {
        toInsert = data[0];
      }

      if (value === 0) {
        toInsert["total_neg"] += 1;
      } else {
        toInsert["total_pos"] += 1;
      }
      toInsert["last_updated"] = now;
      const uploadResponse = await supabase
        .from("aggregate_data")
        .upsert([toInsert]);

      if (uploadResponse.error) {
        showAlert("warning, V2 upload failed");
        console.error("supabaseUploadV2 upload error", uploadResponse.error);
        this.failedUploadsV2.push(toInsert);
      } else {
        console.log("successful v2 upload");
      }

      // TODO retry failed uploads
      this.retryFailedUploadsV2();
      return {
        error: uploadResponse.error,
        data: uploadResponse.data,
      };
    } catch (error) {
      console.error("subabase upload v2 error", error);
    }
  }

  async handleUpdate(projectName, projectType, value) {
    try {
      const today = getTodayDateString();
      const projectData = this.data[projectName];
      const dayValueMap = projectData.dayValueMap;

      const newDayValueMap = {};
      const ogdayValueMap = dayValueMap;
      const ogGraphData = projectData.graphData;
      for (const key in dayValueMap) {
        newDayValueMap[key] = dayValueMap[key];
      }

      if (projectType === "points") {
        newDayValueMap[today] = newDayValueMap[today]
          ? newDayValueMap[today] + value
          : value;
      } else {
        if (!newDayValueMap[today]) {
          newDayValueMap[today] = { success: 0, total: 0 };
        }
        newDayValueMap[today]["total"] += 1;
        newDayValueMap[today]["success"] += value;
      }

      const now = new Date().toLocaleString("en-US", { hour12: false });
      const toInsertV1 = {
        date: today,
        time: now,
        project_name: projectName,
        value: value,
      };

      this.supabaseUploadV1(toInsertV1);
      if (this.uploadV2) {
        this.supabaseUploadV2(today, now, projectName, value);
      }

      this.set_project_data(projectName, "dayValueMap", newDayValueMap);
      let { graphDataTemp, cumulativeGraphDataTemp } = dayValueMapToGraphXYData(
        newDayValueMap,
        projectType
      );
      // const { graphDataTemp, cumulativeGraphDataTemp } =
      // dayValueMapToGraphXYData(dayValueMapTemp, projectType);
      this.set_project_data(projectName, "graphData", graphDataTemp);
      return {
        error: null,
        data: {
          graphData: this.data[projectName]["graphData"],
          dayValueMap: this.data[projectName]["dayValueMap"],
          cumulativeGraphData: cumulativeGraphDataTemp,
          ogGraphData: ogGraphData,
        },
      };
    } catch (error) {
      console.error("data manager handle add point error", error);
      return {
        error: error,
        data: null,
      };
    }
  }
}

const data_manager = new DataManager({ uploadV2: true, downloadV2: false });

export default data_manager;
export { parseDateFromString, dayValueMapToGraphXYData };
