// import { EMAIL, PASSWORD } from "./env.js";
import React, { useEffect, useState } from "react";
import { Alert, StyleSheet, View, Pressable, Text } from "react-native";
import supabase from "./supabase";
import { Button, Input } from "react-native-elements";
import commonStyles from "./commonStyles";
import { NAVIGATOR_COLOR } from "./constants";
import data_manager from "./data_manager";
import useWindowWidth from "./useWindowWidth";
import showAlert from "./alert";

export default function Auth({ navigation }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    async function fetchSession() {
      const currentSession = await supabase.auth.getSession();
      if (currentSession.data.session) {
        setSession(currentSession.data.session);
        return;
      }
    }
    fetchSession();
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      if (_event === "PASSWORD_RECOVERY") {
        setForgotPasswordFlow(false);
        setShowUpdatePassword(true);
      } else {
        setSession(session);
      }
    });
  }, []);

  async function signInWithEmail() {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) showAlert(error.message);
    else {
      console.log("sign in", data.session);
      setSession(data.session);
      navigation.navigate("Habits");
    }
    setLoading(false);
  }

  async function signUpWithEmail() {
    setLoading(true);
    const {
      data: { session },
      error,
    } = await supabase.auth.signUp({
      email: email,
      password: password,
    });

    if (error) showAlert(error.message);
    else {
      showAlert("Please check your inbox for email verification!");
    }
    setLoading(false);
  }

  async function logout() {
    const { error } = await supabase.auth.signOut();
    data_manager.clear();
    setSession(null);
  }

  async function sendPasswordResetEmail(email) {
    setLoading(true);
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://www.habitstat.com/Settings",
      // redirectTo: "http://localhost:19006/Settings",
    });
    if (error) showAlert(error.message);
    else showAlert("Password reset email sent!");
    setLoading(false);
  }

  async function updatePassword(email, password) {
    setLoading(true);
    const { data, error } = await supabase.auth.updateUser({
      password: password,
    });
    if (error) showAlert(error.message);
    else {
      showAlert("Password updated!");
      setShowUpdatePassword(false);
      navigation.navigate("Habits");
    }
    setLoading(false);
  }

  const cardHeaderComponent = (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "hsla(217, 20%, 92%, 1)",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingLeft: 0,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        // height: 55,
        // height: 55,
      }}
    >
      <Text
        style={{
          margin: 0,
          fontSize: 20,
          color: "black",
          marginLeft: 20,
        }}
      >
        {forgotPasswordFlow || showUpdatePassword ? "Reset Password" : "Login"}
      </Text>
    </View>
  );

  const logoutButton = (
    <Pressable
      onPress={logout}
      style={({ pressed }) => {
        return [
          commonStyles.button,
          commonStyles.shadow,
          { backgroundColor: pressed ? NAVIGATOR_COLOR : "white" }, // Change color when pressed
        ];
      }}
    >
      <Text style={[commonStyles.buttonText]}>LOG OUT</Text>
    </Pressable>
  );

  const emailInputComponent = (
    <View style={[styles.verticallySpaced, styles.mt20]}>
      <Input
        label="Email"
        leftIcon={{ type: "font-awesome", name: "envelope" }}
        onChangeText={(text) => setEmail(text)}
        value={email}
        placeholder="email@address.com"
        autoCapitalize={"none"}
      />
    </View>
  );

  const passwordInputComponent = (
    <View style={styles.verticallySpaced}>
      <Input
        label={showUpdatePassword ? "New password" : "Password"}
        leftIcon={{ type: "font-awesome", name: "lock" }}
        onChangeText={(text) => setPassword(text)}
        value={password}
        secureTextEntry={true}
        placeholder="Password"
        autoCapitalize={"none"}
      />
    </View>
  );

  const initialLoginComponent = (
    <React.Fragment>
      <View
        style={[
          styles.verticallySpaced,
          // styles.mt20,
          styles.centerContainer,
        ]}
      >
        <Button
          title="Sign in"
          disabled={loading}
          onPress={() => signInWithEmail()}
          style={styles.buttonStyle}
        />
      </View>

      <View style={[styles.verticallySpaced, styles.centerContainer]}>
        <Button
          title="Sign up"
          disabled={loading}
          onPress={() => signUpWithEmail()}
          style={styles.buttonStyle}
        />
      </View>

      <View style={[styles.verticallySpaced, styles.centerContainer]}>
        <Button
          title="Forgot password?"
          disabled={loading}
          onPress={() => setForgotPasswordFlow(true)}
          style={styles.buttonStyle}
        />
      </View>
    </React.Fragment>
  );

  const sendPasswordResetEmailButton = (
    <View style={[styles.verticallySpaced, styles.centerContainer]}>
      <Button
        title={"Send password reset email"}
        disabled={loading}
        onPress={() => {
          sendPasswordResetEmail(email);
        }}
        style={styles.buttonStyle}
      />
    </View>
  );

  const updatePasswordComponent = (
    <View style={[styles.verticallySpaced, styles.centerContainer]}>
      <Button
        title={"Update password"}
        disabled={loading}
        onPress={() => updatePassword(email, password)}
        style={styles.buttonStyle}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      {session && !showUpdatePassword ? (
        logoutButton
      ) : (
        <View
          style={[
            styles.card,
            commonStyles.shadow,
            { width: Math.min(windowWidth * 0.9, 650) },
          ]}
        >
          {cardHeaderComponent}
          {!showUpdatePassword ? emailInputComponent : null}
          {!forgotPasswordFlow && !showUpdatePassword
            ? passwordInputComponent
            : null}
          {!forgotPasswordFlow && !showUpdatePassword
            ? initialLoginComponent
            : null}
          {!forgotPasswordFlow && showUpdatePassword ? (
            <View style={{ padding: 10 }}>
              {passwordInputComponent}
              {updatePasswordComponent}
            </View>
          ) : null}

          {forgotPasswordFlow ? sendPasswordResetEmailButton : null}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    padding: 12,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    backgroundColor: "white",
    // padding: 20,
    borderRadius: 10,
  },
  verticallySpaced: {
    paddingTop: 4,
    paddingBottom: 4,
    alignSelf: "stretch",
  },
  mt20: {
    marginTop: 20,
  },
  buttonStyle: {
    paddingVertical: 2,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: 200,
  },
  centerContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
