// const BACKGROUNDBLUE1 = "hsla(190, 80%, 64%, 1)";
// const BACKGROUNDBLUE2 = "hsla(190, 77%, 50%, 1)";
const BACKGROUNDBLUE1 = "hsla(193, 72%, 80%, 1)";
const BACKGROUNDBLUE2 = "hsla(190, 80%, 54%, 1)";
const BUTTON_ELEVATION = 10;
const VIEW_ELEVATION = 10;
const graphWidth = 0.9;
const NAVIGATOR_COLOR = "hsla(197, 89%, 52%, 1)";
const TOP_NAV_HEIGHT = 85;

export {
  BACKGROUNDBLUE1,
  BACKGROUNDBLUE2,
  BUTTON_ELEVATION,
  VIEW_ELEVATION,
  graphWidth,
  NAVIGATOR_COLOR,
  TOP_NAV_HEIGHT,
};
