import React from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { Ionicons } from "@expo/vector-icons";
import HabitsScreen from "./HabitsScreen";
import InstructionsScreen from "./InstructionsScreen";
import SettingsScreen from "./SettingsScreen";
import { NAVIGATOR_COLOR, TOP_NAV_HEIGHT } from "./constants";

const Tab =
  Platform.OS === "web"
    ? createMaterialTopTabNavigator()
    : createBottomTabNavigator();

function MyTabs() {
  console.log("Use updated version with redirect fixed");
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        animationEnabled: false,
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          if (route.name === "Habits") {
            iconName = "list";
          } else if (route.name === "Instructions") {
            iconName = focused
              ? "information-circle"
              : "information-circle-outline";
          } else if (route.name === "Settings") {
            iconName = "settings";
          }
          return <Ionicons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: "black",
        headerStyle: {
          backgroundColor: NAVIGATOR_COLOR,
        },
        tabBarStyle: {
          display: "flex",
          backgroundColor: NAVIGATOR_COLOR,
          height: Platform.OS === "web" ? TOP_NAV_HEIGHT : 90,
        },
        // contentContainerStyle: {
        // paddingTop: TOP_NAV_HEIGHT,
        // },
      })}
    >
      <Tab.Screen name="Habits" component={HabitsScreen} />
      <Tab.Screen name="Instructions" component={InstructionsScreen} />
      <Tab.Screen name="Settings" component={SettingsScreen} />
    </Tab.Navigator>
  );
}

export default function App() {
  const linking = {
    prefixes: [],
    config: {
      screens: {
        Habits: "Habits",
        Instructions: "Instructions",
        Settings: "Settings",
      },
    },
  };
  return (
    <NavigationContainer linking={linking}>
      <MyTabs />
    </NavigationContainer>
  );
}
