import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import { useEffect, useState } from "react";
import { FlatList, Button, Pressable } from "react-native";
import supabase from "./supabase";
import styles from "./styles";
import StatsGraphDisplay from "./StatsGraphDisplay";
import Background from "./Background";
import CreateHabitComponent from "./CreateHabitComponent";
import commonStyles from "./commonStyles";
import BetaWarning from "./BetaWarning";
import ReorderHabitsModal from "./reorderHabitsModal";
import data_manager from "./data_manager";

// Define the "Habits" screen
function HabitsScreen({ navigation }) {
  // necessary for logout
  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        fetchProjectListData(session);
      } else {
        console.log("set session null");
        setState({
          habitsList: [],
          loading: false,
          session: null,
        });
      }
    });
  }, []);

  const [state, setState] = useState({
    habitsList: [],
    loading: false,
    session: null,
  });

  useEffect(() => {
    supabase.auth
      .getSession()
      .then((session) => {
        console.log("habitscreen supabase get session useEffect", session);
        if (session && session.data && session.data.session) {
          fetchProjectListData(session.data.session);
        } else {
          fetchProjectListData(null);
        }
      })
      .catch((error) => {
        console.error("habitscreen subabase auth error", error);
      });
  }, []);

  const addNewHabit = (newHabit) => {
    setState({
      habitsList: [...state.habitsList, newHabit],
      loading: false,
      session: state.session,
    });
  };

  const fetchProjectListData = async (session) => {
    const user = await supabase.auth.getUser();

    let newHabitsList = state.habitsList;

    const { data, error } = await data_manager.getHabitsList();
    if (data) {
      newHabitsList = data;
    }

    setState({
      habitsList: newHabitsList,
      loading: false,
      session: session,
    });
  };

  console.log("habitscreen state", state);
  return (
    <Background>
      <BetaWarning />
      {state.session ? (
        <ScrollView scrollEnabled={true}>
          {/* <View> */}
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {state.loading ? <Text>Loading...</Text> : null}
            {state.habitsList.map((item, ind) => {
              return (
                <StatsGraphDisplay
                  key={ind}
                  projectName={item.project_name}
                  projectType={item.project_type}
                />
              );
            })}
          </View>
          <CreateHabitComponent addNewHabit={addNewHabit} />
          {state.habitsList && state.habitsList.length > 1 ? (
            <ReorderHabitsModal
              habitsList={state.habitsList}
              setHabitsList={(newOrder) =>
                setState({ ...state, habitsList: newOrder })
              }
            />
          ) : null}
          {/* </View> */}
        </ScrollView>
      ) : (
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: 500,
          }}
        >
          <View
            style={[
              {
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 10,
                height: 50,
                // width: 300,
                margin: 10,
                padding: 10,
              },
              commonStyles.shadow,
            ]}
          >
            <TouchableOpacity onPress={() => navigation.navigate("Settings")}>
              <Text style={{ fontSize: 18 }}>
                You are not logged in. Please go to{" "}
                <Text style={{ textDecorationLine: "underline" }}>
                  Settings
                </Text>{" "}
                and create an account or log in.
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </Background>
  );
}

export default HabitsScreen;
